
var ortWasmThreaded = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(ortWasmThreaded) {
  ortWasmThreaded = ortWasmThreaded || {};


function c(){k.buffer!=l&&m(k.buffer);return aa}function q(){k.buffer!=l&&m(k.buffer);return ba}function t(){k.buffer!=l&&m(k.buffer);return ca}function v(){k.buffer!=l&&m(k.buffer);return da}function ea(){k.buffer!=l&&m(k.buffer);return fa}var w;w||(w=typeof ortWasmThreaded !== 'undefined' ? ortWasmThreaded : {});var ha,ia;w.ready=new Promise(function(a,b){ha=a;ia=b});
var ja=Object.assign({},w),ka="./this.program",x=(a,b)=>{throw b;},la="object"==typeof window,y="function"==typeof importScripts,z="object"==typeof process&&"object"==typeof process.versions&&"string"==typeof process.versions.node,B=w.ENVIRONMENT_IS_PTHREAD||!1,E="";function ma(a){return w.locateFile?w.locateFile(a,E):E+a}var na,F,G,fs,H,J;
if(z){E=y?require("path").dirname(E)+"/":__dirname+"/";J=()=>{H||(fs=require("fs"),H=require("path"))};na=function(b,d){J();b=H.normalize(b);return fs.readFileSync(b,d?void 0:"utf8")};G=b=>{b=na(b,!0);b.buffer||(b=new Uint8Array(b));return b};F=(b,d,e)=>{J();b=H.normalize(b);fs.readFile(b,function(g,h){g?e(g):d(h.buffer)})};1<process.argv.length&&(ka=process.argv[1].replace(/\\/g,"/"));process.argv.slice(2);process.on("uncaughtException",function(b){if(!(b instanceof K))throw b;});process.on("unhandledRejection",
function(b){throw b;});x=(b,d)=>{if(L())throw process.exitCode=b,d;d instanceof K||M("exiting due to exception: "+d);process.exit(b)};w.inspect=function(){return"[Emscripten Module object]"};let a;try{a=require("worker_threads")}catch(b){throw console.error('The "worker_threads" module is not supported in this node.js build - perhaps a newer version is needed?'),b;}global.Worker=a.Worker}else if(la||y)y?E=self.location.href:"undefined"!=typeof document&&document.currentScript&&(E=document.currentScript.src),
_scriptDir&&(E=_scriptDir),0!==E.indexOf("blob:")?E=E.substr(0,E.replace(/[?#].*/,"").lastIndexOf("/")+1):E="",z||(na=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},y&&(G=a=>{var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),F=(a,b,d)=>{var e=new XMLHttpRequest;e.open("GET",a,!0);e.responseType="arraybuffer";e.onload=()=>{200==e.status||0==e.status&&e.response?b(e.response):d()};e.onerror=
d;e.send(null)});z&&"undefined"==typeof performance&&(global.performance=require("perf_hooks").performance);var oa=console.log.bind(console),pa=console.warn.bind(console);z&&(J(),oa=a=>fs.writeSync(1,a+"\n"),pa=a=>fs.writeSync(2,a+"\n"));var qa=w.print||oa,M=w.printErr||pa;Object.assign(w,ja);ja=null;w.thisProgram&&(ka=w.thisProgram);w.quit&&(x=w.quit);var N;w.wasmBinary&&(N=w.wasmBinary);var noExitRuntime=w.noExitRuntime||!1;"object"!=typeof WebAssembly&&O("no native wasm support detected");
var k,ra,sa=!1,ta="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0;
function ua(a,b,d){b>>>=0;var e=b+d;for(d=b;a[d]&&!(d>=e);)++d;if(16<d-b&&a.buffer&&ta)return ta.decode(a.buffer instanceof SharedArrayBuffer?a.slice(b,d):a.subarray(b,d));for(e="";b<d;){var g=a[b++];if(g&128){var h=a[b++]&63;if(192==(g&224))e+=String.fromCharCode((g&31)<<6|h);else{var n=a[b++]&63;g=224==(g&240)?(g&15)<<12|h<<6|n:(g&7)<<18|h<<12|n<<6|a[b++]&63;65536>g?e+=String.fromCharCode(g):(g-=65536,e+=String.fromCharCode(55296|g>>10,56320|g&1023))}}else e+=String.fromCharCode(g)}return e}
function va(a,b){return(a>>>=0)?ua(q(),a,b):""}
function wa(a,b,d,e){d>>>=0;if(!(0<e))return 0;var g=d;e=d+e-1;for(var h=0;h<a.length;++h){var n=a.charCodeAt(h);if(55296<=n&&57343>=n){var u=a.charCodeAt(++h);n=65536+((n&1023)<<10)|u&1023}if(127>=n){if(d>=e)break;b[d++>>>0]=n}else{if(2047>=n){if(d+1>=e)break;b[d++>>>0]=192|n>>6}else{if(65535>=n){if(d+2>=e)break;b[d++>>>0]=224|n>>12}else{if(d+3>=e)break;b[d++>>>0]=240|n>>18;b[d++>>>0]=128|n>>12&63}b[d++>>>0]=128|n>>6&63}b[d++>>>0]=128|n&63}}b[d>>>0]=0;return d-g}
function xa(a){for(var b=0,d=0;d<a.length;++d){var e=a.charCodeAt(d);127>=e?b++:2047>=e?b+=2:55296<=e&&57343>=e?(b+=4,++d):b+=3}return b}var l,aa,ba,ca,da,fa;B&&(l=w.buffer);function m(a){l=a;w.HEAP8=aa=new Int8Array(a);w.HEAP16=new Int16Array(a);w.HEAP32=ca=new Int32Array(a);w.HEAPU8=ba=new Uint8Array(a);w.HEAPU16=new Uint16Array(a);w.HEAPU32=da=new Uint32Array(a);w.HEAPF32=new Float32Array(a);w.HEAPF64=fa=new Float64Array(a)}var ya=w.INITIAL_MEMORY||16777216;
if(B)k=w.wasmMemory,l=w.buffer;else if(w.wasmMemory)k=w.wasmMemory;else if(k=new WebAssembly.Memory({initial:ya/65536,maximum:65536,shared:!0}),!(k.buffer instanceof SharedArrayBuffer))throw M("requested a shared WebAssembly.Memory but the returned buffer is not a SharedArrayBuffer, indicating that while the browser has SharedArrayBuffer it does not have WebAssembly threads support - you may need to set a flag"),z&&console.log("(on node you may need: --experimental-wasm-threads --experimental-wasm-bulk-memory and also use a recent version)"),
Error("bad memory");k&&(l=k.buffer);ya=l.byteLength;m(l);var za,Aa=[],Ba=[],Ca=[],Da=[],Ea=0;function L(){return noExitRuntime||0<Ea}function Fa(){var a=w.preRun.shift();Aa.unshift(a)}var P=0,Ga=null,Q=null;function O(a){if(B)postMessage({cmd:"onAbort",arg:a});else if(w.onAbort)w.onAbort(a);a="Aborted("+a+")";M(a);sa=!0;a=new WebAssembly.RuntimeError(a+". Build with -sASSERTIONS for more info.");ia(a);throw a;}function Ha(){return R.startsWith("data:application/octet-stream;base64,")}var R;R="ort-wasm-threaded.wasm";
Ha()||(R=ma(R));function Ia(){var a=R;try{if(a==R&&N)return new Uint8Array(N);if(G)return G(a);throw"both async and sync fetching of the wasm failed";}catch(b){O(b)}}
function Ja(){if(!N&&(la||y)){if("function"==typeof fetch&&!R.startsWith("file://"))return fetch(R,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+R+"'";return a.arrayBuffer()}).catch(function(){return Ia()});if(F)return new Promise(function(a,b){F(R,function(d){a(new Uint8Array(d))},b)})}return Promise.resolve().then(function(){return Ia()})}var Ka={};
function K(a){this.name="ExitStatus";this.message="Program terminated with exit("+a+")";this.status=a}function La(a){(a=S.Ma[a])||O();S.Xa(a)}function Ma(a){var b=S.mb();if(!b)return 6;S.Sa.push(b);S.Ma[a.La]=b;b.La=a.La;var d={cmd:"run",start_routine:a.qb,arg:a.jb,pthread_ptr:a.La};b.Ra=()=>{d.time=performance.now();b.postMessage(d,a.vb)};b.loaded&&(b.Ra(),delete b.Ra);return 0}function Na(a){if(B)return T(1,1,a);if(!L()){S.Za();if(w.onExit)w.onExit(a);sa=!0}x(a,new K(a))}
function Oa(a,b){if(!b&&B)throw Pa(a),"unwind";L()||B||(Qa(),U(Ca),Ra(0),Sa[1].length&&Ta(1,10),Sa[2].length&&Ta(2,10),S.Za());Na(a)}
var S={Pa:[],Sa:[],ab:[],Ma:{},Va:function(){B&&S.nb()},xb:function(){},nb:function(){S.receiveObjectTransfer=S.pb;S.threadInitTLS=S.$a;S.setExitStatus=S.Ya;noExitRuntime=!1},Ya:function(){},Za:function(){for(var a of Object.values(S.Ma))S.Xa(a);for(a of S.Pa)a.terminate();S.Pa=[]},Xa:function(a){var b=a.La;delete S.Ma[b];S.Pa.push(a);S.Sa.splice(S.Sa.indexOf(a),1);a.La=0;Ua(b)},pb:function(){},$a:function(){S.ab.forEach(a=>a())},ob:function(a,b){a.onmessage=d=>{d=d.data;var e=d.cmd;a.La&&(S.lb=a.La);
if(d.targetThread&&d.targetThread!=V()){var g=S.Ma[d.yb];g?g.postMessage(d,d.transferList):M('Internal error! Worker sent a message "'+e+'" to target pthread '+d.targetThread+", but that thread no longer exists!")}else if("processProxyingQueue"===e)Va(d.queue);else if("spawnThread"===e)Ma(d);else if("cleanupThread"===e)La(d.thread);else if("killThread"===e)d=d.thread,e=S.Ma[d],delete S.Ma[d],e.terminate(),Ua(d),S.Sa.splice(S.Sa.indexOf(e),1),e.La=0;else if("cancelThread"===e)S.Ma[d.thread].postMessage({cmd:"cancel"});
else if("loaded"===e)a.loaded=!0,b&&b(a),a.Ra&&(a.Ra(),delete a.Ra);else if("print"===e)qa("Thread "+d.threadId+": "+d.text);else if("printErr"===e)M("Thread "+d.threadId+": "+d.text);else if("alert"===e)alert("Thread "+d.threadId+": "+d.text);else if("setimmediate"===d.target)a.postMessage(d);else if("onAbort"===e){if(w.onAbort)w.onAbort(d.arg)}else e&&M("worker sent an unknown command "+e);S.lb=void 0};a.onerror=d=>{M("worker sent an error! "+d.filename+":"+d.lineno+": "+d.message);throw d;};z&&
(a.on("message",function(d){a.onmessage({data:d})}),a.on("error",function(d){a.onerror(d)}),a.on("detachedExit",function(){}));a.postMessage({cmd:"load",urlOrBlob:w.mainScriptUrlOrBlob||_scriptDir,wasmMemory:k,wasmModule:ra})},ib:function(){var a=ma("ort-wasm-threaded.worker.js");S.Pa.push(new Worker(a))},mb:function(){0==S.Pa.length&&(S.ib(),S.ob(S.Pa[0]));return S.Pa.pop()}};w.PThread=S;function U(a){for(;0<a.length;)a.shift()(w)}function Wa(a){var b=Xa();a=a();W(b);return a}
w.establishStackSpace=function(){var a=V(),b=t()[a+44>>2>>>0];a=t()[a+48>>2>>>0];Ya(b,b-a);W(b)};function Pa(a){if(B)return T(2,0,a);try{Oa(a)}catch(b){b instanceof K||"unwind"==b||x(1,b)}}var X=[];w.invokeEntryPoint=function(a,b){var d=X[a];d||(a>=X.length&&(X.length=a+1),X[a]=d=za.get(a));a=d(b);L()?S.Ya(a):Za(a)};function $a(a,b){c().set(a,b>>>0)}
function ab(a){this.Qa=a-24;this.hb=function(b){v()[this.Qa+4>>2>>>0]=b};this.eb=function(b){v()[this.Qa+8>>2>>>0]=b};this.fb=function(){t()[this.Qa>>2>>>0]=0};this.cb=function(){var b=0;c()[this.Qa+12>>0>>>0]=b};this.gb=function(){var b=0;c()[this.Qa+13>>0>>>0]=b};this.Va=function(b,d){this.bb();this.hb(b);this.eb(d);this.fb();this.cb();this.gb()};this.bb=function(){v()[this.Qa+16>>2>>>0]=0}}var bb=0;function cb(a,b,d,e){return B?T(3,1,a,b,d,e):db(a,b,d,e)}
function db(a,b,d,e){if("undefined"==typeof SharedArrayBuffer)return M("Current environment does not support SharedArrayBuffer, pthreads are not available!"),6;var g=[];if(B&&0===g.length)return cb(a,b,d,e);a={qb:d,La:a,jb:e,vb:g};return B?(a.wb="spawnThread",postMessage(a,g),0):Ma(a)}function eb(a,b,d){return B?T(4,1,a,b,d):0}function fb(a,b){if(B)return T(5,1,a,b)}function gb(a,b){if(B)return T(6,1,a,b)}function hb(a,b,d){if(B)return T(7,1,a,b,d)}function ib(a,b,d){return B?T(8,1,a,b,d):0}
function jb(a,b){if(B)return T(9,1,a,b)}function kb(a,b,d){if(B)return T(10,1,a,b,d)}function lb(a,b,d,e){if(B)return T(11,1,a,b,d,e)}function mb(a,b,d,e){if(B)return T(12,1,a,b,d,e)}function nb(a,b,d,e){if(B)return T(13,1,a,b,d,e)}function ob(a){if(B)return T(14,1,a)}function pb(a,b){if(B)return T(15,1,a,b)}function qb(a,b,d){if(B)return T(16,1,a,b,d)}function Va(a){Atomics.store(t(),a>>2,1);V()&&rb(a);Atomics.compareExchange(t(),a>>2,1,0)}w.executeNotifiedProxyingQueue=Va;
function sb(a){return v()[a>>>2]+4294967296*t()[a+4>>>2]}function tb(a,b,d,e,g,h){return B?T(17,1,a,b,d,e,g,h):-52}function ub(a,b,d,e,g,h){if(B)return T(18,1,a,b,d,e,g,h)}function vb(a){var b=xa(a)+1,d=wb(b);d&&wa(a,c(),d,b);return d}
function xb(a,b,d){function e(A){return(A=A.toTimeString().match(/\(([A-Za-z ]+)\)$/))?A[1]:"GMT"}if(B)return T(19,1,a,b,d);var g=(new Date).getFullYear(),h=new Date(g,0,1),n=new Date(g,6,1);g=h.getTimezoneOffset();var u=n.getTimezoneOffset(),D=Math.max(g,u);t()[a>>2>>>0]=60*D;t()[b>>2>>>0]=Number(g!=u);a=e(h);b=e(n);a=vb(a);b=vb(b);u<g?(v()[d>>2>>>0]=a,v()[d+4>>2>>>0]=b):(v()[d>>2>>>0]=b,v()[d+4>>2>>>0]=a)}function yb(a,b,d){yb.kb||(yb.kb=!0,xb(a,b,d))}var Y,zb;
zb=z?()=>{var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:B?()=>performance.now()-w.__performance_now_clock_drift:()=>performance.now();function T(a,b){var d=arguments.length-2,e=arguments;return Wa(()=>{for(var g=Ab(8*d),h=g>>3,n=0;n<d;n++){var u=e[2+n];ea()[h+n>>>0]=u}return Bb(a,d,g,b)})}var Cb=[],Db={};
function Eb(){if(!Fb){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"==typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:ka||"./this.program"},b;for(b in Db)void 0===Db[b]?delete a[b]:a[b]=Db[b];var d=[];for(b in a)d.push(b+"="+a[b]);Fb=d}return Fb}var Fb;
function Gb(a,b){if(B)return T(20,1,a,b);var d=0;Eb().forEach(function(e,g){var h=b+d;g=v()[a+4*g>>2>>>0]=h;for(h=0;h<e.length;++h)c()[g++>>0>>>0]=e.charCodeAt(h);c()[g>>0>>>0]=0;d+=e.length+1});return 0}function Kb(a,b){if(B)return T(21,1,a,b);var d=Eb();v()[a>>2>>>0]=d.length;var e=0;d.forEach(function(g){e+=g.length+1});v()[b>>2>>>0]=e;return 0}function Lb(a){return B?T(22,1,a):52}function Mb(a,b,d,e){return B?T(23,1,a,b,d,e):52}function Nb(a,b,d,e,g){return B?T(24,1,a,b,d,e,g):70}
var Sa=[null,[],[]];function Ta(a,b){var d=Sa[a];0===b||10===b?((1===a?qa:M)(ua(d,0)),d.length=0):d.push(b)}function Ob(a,b,d,e){if(B)return T(25,1,a,b,d,e);for(var g=0,h=0;h<d;h++){var n=v()[b>>2>>>0],u=v()[b+4>>2>>>0];b+=8;for(var D=0;D<u;D++)Ta(a,q()[n+D>>>0]);g+=u}v()[e>>2>>>0]=g;return 0}function Z(a){return 0===a%4&&(0!==a%100||0===a%400)}var Pb=[31,29,31,30,31,30,31,31,30,31,30,31],Qb=[31,28,31,30,31,30,31,31,30,31,30,31];function Rb(a){var b=Array(xa(a)+1);wa(a,b,0,b.length);return b}
function Sb(a,b,d,e){function g(f,p,r){for(f="number"==typeof f?f.toString():f||"";f.length<p;)f=r[0]+f;return f}function h(f,p){return g(f,p,"0")}function n(f,p){function r(Hb){return 0>Hb?-1:0<Hb?1:0}var I;0===(I=r(f.getFullYear()-p.getFullYear()))&&0===(I=r(f.getMonth()-p.getMonth()))&&(I=r(f.getDate()-p.getDate()));return I}function u(f){switch(f.getDay()){case 0:return new Date(f.getFullYear()-1,11,29);case 1:return f;case 2:return new Date(f.getFullYear(),0,3);case 3:return new Date(f.getFullYear(),
0,2);case 4:return new Date(f.getFullYear(),0,1);case 5:return new Date(f.getFullYear()-1,11,31);case 6:return new Date(f.getFullYear()-1,11,30)}}function D(f){var p=f.Na;for(f=new Date((new Date(f.Oa+1900,0,1)).getTime());0<p;){var r=f.getMonth(),I=(Z(f.getFullYear())?Pb:Qb)[r];if(p>I-f.getDate())p-=I-f.getDate()+1,f.setDate(1),11>r?f.setMonth(r+1):(f.setMonth(0),f.setFullYear(f.getFullYear()+1));else{f.setDate(f.getDate()+p);break}}r=new Date(f.getFullYear()+1,0,4);p=u(new Date(f.getFullYear(),
0,4));r=u(r);return 0>=n(p,f)?0>=n(r,f)?f.getFullYear()+1:f.getFullYear():f.getFullYear()-1}var A=t()[e+40>>2>>>0];e={tb:t()[e>>2>>>0],sb:t()[e+4>>2>>>0],Ta:t()[e+8>>2>>>0],Wa:t()[e+12>>2>>>0],Ua:t()[e+16>>2>>>0],Oa:t()[e+20>>2>>>0],Ka:t()[e+24>>2>>>0],Na:t()[e+28>>2>>>0],zb:t()[e+32>>2>>>0],rb:t()[e+36>>2>>>0],ub:A?va(A):""};d=va(d);A={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C",
"%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var C in A)d=d.replace(new RegExp(C,"g"),A[C]);var Ib="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),Jb="January February March April May June July August September October November December".split(" ");A={"%a":function(f){return Ib[f.Ka].substring(0,3)},"%A":function(f){return Ib[f.Ka]},
"%b":function(f){return Jb[f.Ua].substring(0,3)},"%B":function(f){return Jb[f.Ua]},"%C":function(f){return h((f.Oa+1900)/100|0,2)},"%d":function(f){return h(f.Wa,2)},"%e":function(f){return g(f.Wa,2," ")},"%g":function(f){return D(f).toString().substring(2)},"%G":function(f){return D(f)},"%H":function(f){return h(f.Ta,2)},"%I":function(f){f=f.Ta;0==f?f=12:12<f&&(f-=12);return h(f,2)},"%j":function(f){for(var p=0,r=0;r<=f.Ua-1;p+=(Z(f.Oa+1900)?Pb:Qb)[r++]);return h(f.Wa+p,3)},"%m":function(f){return h(f.Ua+
1,2)},"%M":function(f){return h(f.sb,2)},"%n":function(){return"\n"},"%p":function(f){return 0<=f.Ta&&12>f.Ta?"AM":"PM"},"%S":function(f){return h(f.tb,2)},"%t":function(){return"\t"},"%u":function(f){return f.Ka||7},"%U":function(f){return h(Math.floor((f.Na+7-f.Ka)/7),2)},"%V":function(f){var p=Math.floor((f.Na+7-(f.Ka+6)%7)/7);2>=(f.Ka+371-f.Na-2)%7&&p++;if(p)53==p&&(r=(f.Ka+371-f.Na)%7,4==r||3==r&&Z(f.Oa)||(p=1));else{p=52;var r=(f.Ka+7-f.Na-1)%7;(4==r||5==r&&Z(f.Oa%400-1))&&p++}return h(p,2)},
"%w":function(f){return f.Ka},"%W":function(f){return h(Math.floor((f.Na+7-(f.Ka+6)%7)/7),2)},"%y":function(f){return(f.Oa+1900).toString().substring(2)},"%Y":function(f){return f.Oa+1900},"%z":function(f){f=f.rb;var p=0<=f;f=Math.abs(f)/60;return(p?"+":"-")+String("0000"+(f/60*100+f%60)).slice(-4)},"%Z":function(f){return f.ub},"%%":function(){return"%"}};d=d.replace(/%%/g,"\x00\x00");for(C in A)d.includes(C)&&(d=d.replace(new RegExp(C,"g"),A[C](e)));d=d.replace(/\0\0/g,"%");C=Rb(d);if(C.length>
b)return 0;$a(C,a);return C.length-1}S.Va();
var Tb=[null,Na,Pa,cb,eb,fb,gb,hb,ib,jb,kb,lb,mb,nb,ob,pb,qb,tb,ub,xb,Gb,Kb,Lb,Mb,Nb,Ob],Vb={b:function(a){return wb(a+24)+24},c:function(a,b,d){(new ab(a)).Va(b,d);bb++;throw a;},L:function(a){Ub(a,!y,1,!la);S.$a()},m:function(a){B?postMessage({cmd:"cleanupThread",thread:a}):La(a)},D:db,i:eb,S:fb,y:gb,B:hb,t:ib,Q:jb,I:kb,P:lb,o:mb,z:nb,w:ob,R:pb,x:qb,q:function(){},j:function(){O("To use dlopen, you need enable dynamic linking, see https://github.com/emscripten-core/emscripten/wiki/Linking")},r:function(){O("To use dlopen, you need enable dynamic linking, see https://github.com/emscripten-core/emscripten/wiki/Linking")},
p:function(){return Date.now()},E:function(){return 2097152},U:function(){return!0},F:function(a,b,d,e){if(a==b)setTimeout(()=>Va(e));else if(B)postMessage({targetThread:a,cmd:"processProxyingQueue",queue:e});else{a=S.Ma[a];if(!a)return;a.postMessage({cmd:"processProxyingQueue",queue:e})}return 1},K:function(){return-1},V:function(a,b){a=new Date(1E3*sb(a));t()[b>>2>>>0]=a.getUTCSeconds();t()[b+4>>2>>>0]=a.getUTCMinutes();t()[b+8>>2>>>0]=a.getUTCHours();t()[b+12>>2>>>0]=a.getUTCDate();t()[b+16>>2>>>
0]=a.getUTCMonth();t()[b+20>>2>>>0]=a.getUTCFullYear()-1900;t()[b+24>>2>>>0]=a.getUTCDay();a=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0;t()[b+28>>2>>>0]=a},W:function(a,b){a=new Date(1E3*sb(a));t()[b>>2>>>0]=a.getSeconds();t()[b+4>>2>>>0]=a.getMinutes();t()[b+8>>2>>>0]=a.getHours();t()[b+12>>2>>>0]=a.getDate();t()[b+16>>2>>>0]=a.getMonth();t()[b+20>>2>>>0]=a.getFullYear()-1900;t()[b+24>>2>>>0]=a.getDay();var d=new Date(a.getFullYear(),0,1),e=(a.getTime()-d.getTime())/864E5|0;t()[b+
28>>2>>>0]=e;t()[b+36>>2>>>0]=-(60*a.getTimezoneOffset());e=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();d=d.getTimezoneOffset();a=(e!=d&&a.getTimezoneOffset()==Math.min(d,e))|0;t()[b+32>>2>>>0]=a},X:function(a){var b=new Date(t()[a+20>>2>>>0]+1900,t()[a+16>>2>>>0],t()[a+12>>2>>>0],t()[a+8>>2>>>0],t()[a+4>>2>>>0],t()[a>>2>>>0],0),d=t()[a+32>>2>>>0],e=b.getTimezoneOffset(),g=new Date(b.getFullYear(),0,1),h=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),n=g.getTimezoneOffset(),u=Math.min(n,
h);0>d?t()[a+32>>2>>>0]=Number(h!=n&&u==e):0<d!=(u==e)&&(h=Math.max(n,h),b.setTime(b.getTime()+6E4*((0<d?u:h)-e)));t()[a+24>>2>>>0]=b.getDay();d=(b.getTime()-g.getTime())/864E5|0;t()[a+28>>2>>>0]=d;t()[a>>2>>>0]=b.getSeconds();t()[a+4>>2>>>0]=b.getMinutes();t()[a+8>>2>>>0]=b.getHours();t()[a+12>>2>>>0]=b.getDate();t()[a+16>>2>>>0]=b.getMonth();return b.getTime()/1E3|0},G:tb,H:ub,Y:yb,d:function(){O("")},n:function(){if(!z&&!y){var a="Blocking on the main thread is very dangerous, see https://emscripten.org/docs/porting/pthreads.html#blocking-on-the-main-browser-thread";
Y||(Y={});Y[a]||(Y[a]=1,z&&(a="warning: "+a),M(a))}},v:function(){return 4294901760},f:zb,O:function(a,b,d){q().copyWithin(a>>>0,b>>>0,b+d>>>0)},g:function(){return z?require("os").cpus().length:navigator.hardwareConcurrency},J:function(a,b,d){Cb.length=b;d>>=3;for(var e=0;e<b;e++)Cb[e]=ea()[d+e>>>0];return(0>a?Ka[-a-1]:Tb[a]).apply(null,Cb)},u:function(a){var b=q().length;a>>>=0;if(a<=b||4294901760<a)return!1;for(var d=1;4>=d;d*=2){var e=b*(1+.2/d);e=Math.min(e,a+100663296);var g=Math;e=Math.max(a,
e);g=g.min.call(g,4294901760,e+(65536-e%65536)%65536);a:{try{k.grow(g-l.byteLength+65535>>>16);m(k.buffer);var h=1;break a}catch(n){}h=void 0}if(h)return!0}return!1},T:function(){throw"unwind";},M:Gb,N:Kb,C:Oa,h:Lb,l:Mb,s:Nb,k:Ob,a:k||w.wasmMemory,A:Sb,e:function(a,b,d,e){return Sb(a,b,d,e)}};
(function(){function a(g,h){w.asm=g.exports;S.ab.push(w.asm.wa);za=w.asm.ya;Ba.unshift(w.asm.Z);ra=h;B||(P--,w.monitorRunDependencies&&w.monitorRunDependencies(P),0==P&&(null!==Ga&&(clearInterval(Ga),Ga=null),Q&&(g=Q,Q=null,g())))}function b(g){a(g.instance,g.module)}function d(g){return Ja().then(function(h){return WebAssembly.instantiate(h,e)}).then(function(h){return h}).then(g,function(h){M("failed to asynchronously prepare wasm: "+h);O(h)})}var e={a:Vb};B||(P++,w.monitorRunDependencies&&w.monitorRunDependencies(P));
if(w.instantiateWasm)try{return w.instantiateWasm(e,a)}catch(g){return M("Module.instantiateWasm callback failed with error: "+g),!1}(function(){return N||"function"!=typeof WebAssembly.instantiateStreaming||Ha()||R.startsWith("file://")||z||"function"!=typeof fetch?d(b):fetch(R,{credentials:"same-origin"}).then(function(g){return WebAssembly.instantiateStreaming(g,e).then(b,function(h){M("wasm streaming compile failed: "+h);M("falling back to ArrayBuffer instantiation");return d(b)})})})().catch(ia);
return{}})();w.___wasm_call_ctors=function(){return(w.___wasm_call_ctors=w.asm.Z).apply(null,arguments)};w._OrtInit=function(){return(w._OrtInit=w.asm._).apply(null,arguments)};w._OrtCreateSessionOptions=function(){return(w._OrtCreateSessionOptions=w.asm.$).apply(null,arguments)};w._OrtAppendExecutionProvider=function(){return(w._OrtAppendExecutionProvider=w.asm.aa).apply(null,arguments)};w._OrtAddSessionConfigEntry=function(){return(w._OrtAddSessionConfigEntry=w.asm.ba).apply(null,arguments)};
w._OrtReleaseSessionOptions=function(){return(w._OrtReleaseSessionOptions=w.asm.ca).apply(null,arguments)};w._OrtCreateSession=function(){return(w._OrtCreateSession=w.asm.da).apply(null,arguments)};w._OrtReleaseSession=function(){return(w._OrtReleaseSession=w.asm.ea).apply(null,arguments)};w._OrtGetInputCount=function(){return(w._OrtGetInputCount=w.asm.fa).apply(null,arguments)};w._OrtGetOutputCount=function(){return(w._OrtGetOutputCount=w.asm.ga).apply(null,arguments)};
w._OrtGetInputName=function(){return(w._OrtGetInputName=w.asm.ha).apply(null,arguments)};w._OrtGetOutputName=function(){return(w._OrtGetOutputName=w.asm.ia).apply(null,arguments)};w._OrtFree=function(){return(w._OrtFree=w.asm.ja).apply(null,arguments)};w._OrtCreateTensor=function(){return(w._OrtCreateTensor=w.asm.ka).apply(null,arguments)};w._OrtGetTensorData=function(){return(w._OrtGetTensorData=w.asm.la).apply(null,arguments)};
w._OrtReleaseTensor=function(){return(w._OrtReleaseTensor=w.asm.ma).apply(null,arguments)};w._OrtCreateRunOptions=function(){return(w._OrtCreateRunOptions=w.asm.na).apply(null,arguments)};w._OrtAddRunConfigEntry=function(){return(w._OrtAddRunConfigEntry=w.asm.oa).apply(null,arguments)};w._OrtReleaseRunOptions=function(){return(w._OrtReleaseRunOptions=w.asm.pa).apply(null,arguments)};w._OrtRun=function(){return(w._OrtRun=w.asm.qa).apply(null,arguments)};
w._OrtEndProfiling=function(){return(w._OrtEndProfiling=w.asm.ra).apply(null,arguments)};var V=w._pthread_self=function(){return(V=w._pthread_self=w.asm.sa).apply(null,arguments)},wb=w._malloc=function(){return(wb=w._malloc=w.asm.ta).apply(null,arguments)};w._free=function(){return(w._free=w.asm.ua).apply(null,arguments)};var Ra=w._fflush=function(){return(Ra=w._fflush=w.asm.va).apply(null,arguments)};w.__emscripten_tls_init=function(){return(w.__emscripten_tls_init=w.asm.wa).apply(null,arguments)};
var Qa=w.___funcs_on_exit=function(){return(Qa=w.___funcs_on_exit=w.asm.xa).apply(null,arguments)},Ub=w.__emscripten_thread_init=function(){return(Ub=w.__emscripten_thread_init=w.asm.za).apply(null,arguments)};w.__emscripten_thread_crashed=function(){return(w.__emscripten_thread_crashed=w.asm.Aa).apply(null,arguments)};
var Bb=w._emscripten_run_in_main_runtime_thread_js=function(){return(Bb=w._emscripten_run_in_main_runtime_thread_js=w.asm.Ba).apply(null,arguments)},rb=w.__emscripten_proxy_execute_task_queue=function(){return(rb=w.__emscripten_proxy_execute_task_queue=w.asm.Ca).apply(null,arguments)},Ua=w.__emscripten_thread_free_data=function(){return(Ua=w.__emscripten_thread_free_data=w.asm.Da).apply(null,arguments)},Za=w.__emscripten_thread_exit=function(){return(Za=w.__emscripten_thread_exit=w.asm.Ea).apply(null,
arguments)},Ya=w._emscripten_stack_set_limits=function(){return(Ya=w._emscripten_stack_set_limits=w.asm.Fa).apply(null,arguments)},Xa=w.stackSave=function(){return(Xa=w.stackSave=w.asm.Ga).apply(null,arguments)},W=w.stackRestore=function(){return(W=w.stackRestore=w.asm.Ha).apply(null,arguments)},Ab=w.stackAlloc=function(){return(Ab=w.stackAlloc=w.asm.Ia).apply(null,arguments)};w.___cxa_is_pointer_type=function(){return(w.___cxa_is_pointer_type=w.asm.Ja).apply(null,arguments)};w.UTF8ToString=va;
w.stringToUTF8=function(a,b,d){return wa(a,q(),b,d)};w.lengthBytesUTF8=xa;w.keepRuntimeAlive=L;w.wasmMemory=k;w.stackSave=Xa;w.stackRestore=W;w.stackAlloc=Ab;w.ExitStatus=K;w.PThread=S;var Wb;Q=function Xb(){Wb||Yb();Wb||(Q=Xb)};
function Yb(){function a(){if(!Wb&&(Wb=!0,w.calledRun=!0,!sa)){B||U(Ba);ha(w);if(w.onRuntimeInitialized)w.onRuntimeInitialized();if(!B){if(w.postRun)for("function"==typeof w.postRun&&(w.postRun=[w.postRun]);w.postRun.length;){var b=w.postRun.shift();Da.unshift(b)}U(Da)}}}if(!(0<P))if(B)ha(w),B||U(Ba),postMessage({cmd:"loaded"});else{if(w.preRun)for("function"==typeof w.preRun&&(w.preRun=[w.preRun]);w.preRun.length;)Fa();U(Aa);0<P||(w.setStatus?(w.setStatus("Running..."),setTimeout(function(){setTimeout(function(){w.setStatus("")},
1);a()},1)):a())}}if(w.preInit)for("function"==typeof w.preInit&&(w.preInit=[w.preInit]);0<w.preInit.length;)w.preInit.pop()();Yb();


  return ortWasmThreaded.ready
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasmThreaded;
else if (typeof define === 'function' && define['amd'])
  define([], function() { return ortWasmThreaded; });
else if (typeof exports === 'object')
  exports["ortWasmThreaded"] = ortWasmThreaded;
